import HeaderTitle from "components/header-title/HeaderTitle";
import { useAppDispatch } from "hooks/appHook";
import { useProfile } from "hooks/useProfile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getListBank } from "store/actions/bankAction";
import CreateAccountBVB from "./CreateAccountBVB";
import CreateAccountOCB from "./CreateAccountOCB";
import {
  BankCell,
  BtnCell,
  Cell,
  NotiCell,
  OpenAccCell,
  RecommendTextBox,
} from "./common";

interface BankIProps {
  id: number;
  name: string;
  full_name: string;
  swift_code: string;
  va_from_bank: null;
  bank_code: string;
  bic: number;
  logo_url: string;
  logo_url2: string;
  callback_url: null;
}

const FormBankStep1 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [listBank, setListBank] = useState<any>([]);
  const [listBankTest, setListBankTest] = useState<any>([]);
  const [openQr, setOpenQr] = useState("");
  const { data: profile } = useProfile();
  const accountType = profile?.data?.account_type;
  const isOnboard = useLocation().pathname.split("/")[1] == "register";

  const listCol = [
    "Tên ngân hàng",
    "Thủ tục kết nối",
    "Mở tài khoản (nếu chưa có)",
    "Thông báo kết quả giao dịch",
    "",
  ];

  const getData = async () => {
    try {
      const res: any = await dispatch(getListBank({}));
      if (res.meta.requestStatus === "fulfilled") {
        const data: BankIProps[] = res?.payload?.data?.data;
        setListBank(data);
        setListBankTest(
          data
            // .filter((bank) => bank.bank_code == "VCCB")
            .reduce(
              (acc: any, bank) => {
                acc.logo.push(bank.logo_url2);
                acc.name.push(bank.name);
                acc.bank_code.push(bank.bank_code);
                return acc;
              },
              { logo: [], name: [], bank_code: [] }
            )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-col items-center gap-6">
      {!isOnboard && (
        <HeaderTitle
          title="Ngân hàng"
          subTitle="Kết nối ngân hàng"
          onClickTitle={() => navigate("/admin/bank")}
        />
      )}
      <div className="w-full space-y-8 rounded-2xl md:bg-white md:p-8 md:shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)]">
        {isOnboard && (
          <div className="text-[24px] font-bold leading-6">
            Kết nối ngân hàng
          </div>
        )}

        <div
          style={{ gridTemplateColumns: `repeat(${listCol.length}, auto)` }}
          className="custom-scrollbar grid overflow-x-auto"
        >
          {listCol.map((col, colIndex) => {
            const { logo, name, bank_code } = listBankTest;
            return (
              <div key={colIndex} className="flex flex-col justify-between">
                <div
                  className={`whitespace-nowrap py-3 ${
                    colIndex != listCol.length - 1 && "pr-6"
                  } border-b border-[#E8E8E8] text-sm font-medium text-[#8C8C8C]`}
                >
                  <p className="h-5">{col}</p>
                </div>
                {logo?.map((item: any, rowIndex: number) => {
                  return colIndex == 0 ? (
                    <BankCell logo={logo[rowIndex]} code={name[rowIndex]} />
                  ) : colIndex == 1 ? (
                    <Cell>Trực tuyến, kích hoạt ngay</Cell>
                  ) : colIndex == 2 ? (
                    <OpenAccCell
                      bankCode={bank_code[rowIndex]}
                      accountType={accountType}
                      setOpenQr={setOpenQr}
                    />
                  ) : colIndex == 3 ? (
                    <NotiCell
                      moneyIn
                      moneyOut={bank_code[rowIndex] === "VPB"}
                    />
                  ) : (
                    <BtnCell
                      bankCode={bank_code[rowIndex]}
                      accountType={accountType}
                      setOpenQr={setOpenQr}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* {listBank?.map((bank: any, index: number) => {
          const { logo, name, bank_code } = listBankTest;
          return (
            <div
              key={index}
              className="flex w-full gap-4 border-[#E8E8E8] bg-white p-4 md:items-center md:border-b md-max:flex-col md-max:rounded-lg md-max:shadow-[0px_5px_16px_0px_#080F340F]"
            >
              <BankCell logo={logo[index]} code={name[index]} />
              <Cell>Trực tuyến, kích hoạt ngay</Cell>
              <OpenAccCell
                bankCode={bank_code[index]}
                accountType={accountType}
                setOpenQr={setOpenQr}
              />
              <NotiCell moneyIn moneyOut={bank_code[index] === "VPB"} />
              <BtnCell bankCode={bank_code[index]} accountType={accountType} />
            </div>
          );
        })} */}

        <RecommendTextBox />
      </div>
      <CreateAccountOCB onClose={setOpenQr} isOpen={openQr == "OCB"} />
      <CreateAccountBVB
        onClose={setOpenQr}
        isOpen={openQr == "VCCB"}
        setOpenQr={setOpenQr}
      />
    </div>
  );
};

export default FormBankStep1;
